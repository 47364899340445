//Variables
$font-stack: Rubik, sans-serif;
$br-green: #00a650;
$br-active-blue: #0064b0;

#big-wrapper {
  height: 2350px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.backdrop {
  background-color: #fafafa;
  max-height: 130vh;
  display: flex;
}

#TagList {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
}

.content {
  width: 100%;
}

.icon {
  display: inline-flex;
  align-self: center;
}

.icon.baseline svg,
.icon img {
  top: 0.5em;
  position: relative;
}

.icon-number {
  position: absolute;
  display: inline;
  color: #00a650;
  font-family: $font-stack;
  font-weight: 500;
  font-size: 10px;
  left: 7px;
  top: -16px;
}

.home-backdrop {
  background-color: #ebeef0;
  height: calc(100vh - 74px);
  width: 100%;
  display: flex;
  overflow-y: scroll;
}

.profile-div {
  display: flex;
  align-items: center;
}

.profile-label-spacing {
  // width: "20px";
  margin-right: 8px;
}

#home-wrapper {
  vertical-align: top;
  display: inline-block;
  background-position: right top;
  background-repeat: repeat-y;
  height: 100%;
  width: 100%;
}

.home-button {
  float: right;
  margin-top: 20px;
}

.green-button {
  background-color: #008741 !important;
  color: #fff !important;
  font-family: $font-stack !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 8px 20px 8px 20px !important;
  margin-right: 45px !important;
  margin-bottom: 20px !important;
  text-align: left !important;
  margin-top: 20px !important;
}

#pipeline-button {
  padding: 8px 13px;
  border-radius: 4px;
  color: #fff;
  font-family: $font-stack;
  font-size: 15px;
  font-weight: 500;
}

.green-button.Mui-disabled {
  background-color: #d3d3d3;
}

.green-button:hover {
  background-color: #008741;
}

.table {
  height: 520px;
}

.table-button {
  margin-top: 22px;
}

.table-button:hover {
  background-color: #fff;
}

.table-column-headers>th {
  font-family: $font-stack;
  font-weight: 500;
}

.table-search-bar {
  width: 100%;
}

.table-search-bar>th {
  border: none;
}

.table-search {
  padding-left: 15%;
}

.home-table {
  width: 100%;
  margin: 0px 0px;
  overflow: hidden;
}

.table-wrapper-home {
  width: 96%;
  margin: 10px 35px 0px 28px;
  background: linear-gradient(180deg, transparent 8.5%, #fff 8.5%, #fff 91.5%);
  box-shadow: -20px 20px 14px -20px #c8cdd1, 20px 20px 14px -20px #c8cdd1;
}

.table-wrapper {
  width: 96%;
  margin: 10px 35px 0px 28px;
  border-radius: 10px;
  background: linear-gradient(0deg, #ebeef0 55px, #fff 45px, #fff 100%);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.table-header {
  border: none;
  font-family: $font-stack;
  font-weight: 500;
  width: 100%;
  background: transparent;
}

.table-body td {
  font-family: $font-stack;
}

.table-pagination p {
  font-family: $font-stack;
}

.sample-files {
  margin-bottom: 20px;
  margin-top: -10px;
}

.sample-files-label {
  margin-bottom: 1rem;
}

.experiment-details-content {
  margin-left: 25px;
  margin-bottom: 100px;
}

#blue-button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}

#blue-button,
.blue-button {
  padding: 8px 13px;
  //border-radius: 4px;
  //border: solid 1px $br-active-blue;
  color: $br-active-blue;
  font-family: $font-stack;
  font-size: 15px;
  font-weight: 600 !important;
}

.blue-text {
  color: $br-active-blue;
}

.blue-link {
  font-family: Rubik, sans-serif;
  color: $br-active-blue;
  text-decoration: none;
}

.blue-link:hover {
  font-family: Rubik, sans-serif;
  color: #033255;
}

.link-image {
  vertical-align: middle;
  margin-right: 10px;
}

.cancel-image {
  vertical-align: middle;
  float: right;
}

.MuiDataGrid-cell .orange-link {
  color: #d44300;
  text-decoration: none;
}

.orange-link:hover {
  color: #872b00;
}

.light-orange-link {
  color: #ffa263 !important;
  text-decoration: none;
}

.green-link {
  color: $br-green;
  text-decoration: none;
}

.side-panel-wrapper {
  vertical-align: top;
  display: inline-block;
  background-repeat: no-repeat;
  height: 100%;
  width: 16%;
  color: #fff;
  background-color: #3b445c;
}

.side-panel-header {
  font-family: $font-stack;
  font-weight: 300;
  margin-bottom: 35px;
}

.side-panel-text {
  font-family: $font-stack;
  font-weight: 400;
  margin-top: 60px;
  padding-bottom: 20px;

  a {
    display: block;
    margin-top: 5px;
    margin-bottom: 0px;
  }
}

.panel-wrapper {
  display: flex;
}

.nav {
  background-color: #fff !important;
  border-bottom: solid 4px $br-green;
  height: 74px;
  padding-top: 3px;
}

.nav-text {
  display: inline;
  color: #00a650;
  font-family: $font-stack;
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
  vertical-align: middle;
}

.nav-icon {
  margin-left: 30px;
  position: relative;
  text-align: center;
}

// .side-nav {
//   background-color: #fff;
//   height: 130vh;
//   padding-top: 10px;
//   box-shadow: 1px 0 0 0 #d2d4d6;
//   width: 6%;
//   min-width: 135px;
//   z-index: 2;
// }
.side-nav {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  /* Keeps space between each element */
  align-items: left;
  background-color: #fff;
  height: 100vh;
  /* Tech Debt layouts! Or consider using a more relative unit */
  padding-top: 10px;
  box-shadow: 1px 0 0 0 #d2d4d6;
  width: 6%;
  min-width: 135px;
  z-index: 2;
  /* Smooth transition for collapse */
  transition: all 0.3s ease-in-out;
}

.side-nav-collapsed {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: stretch;
  background-color: #fff;
  height: 100vh;
  padding-top: 10px;
  box-shadow: 1px 0 0 0 #d2d4d6;
  width: 3%;
  min-width: 52px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.side-nav-button {
  width: 100%;
  /* Take up full container width */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #6d7687 !important;
  font-family: $font-stack !important;
  white-space: nowrap;
  /* Prevents text from wrapping */
  overflow: hidden;
  /* Hides any overflowing text */
  text-overflow: ellipsis;
  /* Adds "..." when text overflows */
  transition: all 0.3s ease-in-out;
  text-align: left;

  /* Added for text alignment */


}

.side-nav-button:hover {
  color: #006932;
}

.side-nav-button-active {
  //box-shadow: 0 2px $br-green;
  box-shadow: inset 0 -2px 0 0 $br-green;
  width: 100%;
  display: flex;
  align-items: center;
  /* Vertically align content */
  justify-content: flex-start;
  /* Align text and icons to the left */
  color: $br-green !important;
  text-align: left;

}

.side-nav-img {
  margin-right: 5px;
  //float: left;
  //width: 100%;
  transition: all 0.3s ease-in-out;
}

.side-link,
.side-link-collapsed {
  width: 100%;
  margin-left: 4px;
  margin-bottom: 30px;

  flex-shrink: 0;
  /* Prevents the items from shrinking when collapsing */
  align-items: left;
  /* Centers items vertically for consistent layout */

  /* Ensures the image and text stay on the same line */
}

.collapse-link {
  margin-left: 22px;
}


.expand-link {
  margin-left: 22px;

}

.header {
  display: inline-block;
  width: 75%;
  font-family: $font-stack;
  font-weight: 400;
  font-size: 25px;
  color: #3d3d3d;
  margin-left: 32px;
}

.small-header {
  font-family: $font-stack;
  font-weight: 400;
  font-size: 20px;
  color: #3d3d3d;
  padding: 24px 24px 0px 24px;
}

.experiment-title {
  font-size: 12px !important;
  margin-bottom: -10px;
}

.select-sample {
  margin-top: 18px !important;
}

.select-sample .css-1z7n62 {
  display: none;
}

.medium-header {
  font-family: $font-stack;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin: 32px 4px 32px 32px;
}

.medium-text {
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 600;
}

.sub-header {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}

.edit-notes-header {
  padding-bottom: 18px;
}

.edit-notes-header>p {
  padding-top: 0;
  margin-top: 0;
}

.edit-notes-header>* {
  display: inline;
}

.edit-notes-footer {
  float: left;
  width: 100%;
  margin-top: 0px;
}

.file-upload {
  text-decoration: none;
  color: #6a6a6a;
}

.file-upload div {
  border: dashed 2px $br-active-blue;
  margin: 16px 32px 12px 32px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
}

.file-upload p {
  font-family: $font-stack;
  font-weight: 400;
  font-size: 20px;
}

.settings {
  margin: 12px 32px 32px 32px;
  border-radius: 10px;
  height: 290px;
}

.sliderFormControl {
  margin-top: 30px !important;
}

.settings-field-header {
  font-family: $font-stack;
  font-size: 16px;
  font-weight: 400;
}

.settings-field-express-header {
  font-family: $font-stack;
  font-size: 12px;
  font-weight: 400;
}

.settings-expanded {
  margin: 12px 32px 32px 32px;
  border-radius: 10px;
  height: 122%;
  padding-bottom: 80px;
}

.settings-field {
  padding: 0px 59px 25px 0px;
  width: 100%;
}

.tags-header {
  margin-top: 0px;
  font-family: $font-stack;
  font-size: 12px;
  color: #727272;
}

.tags-field {
  padding: 0px 10px 26px 24px;
}

#pipeline-progress-box {
  background-color: #ededed;
  color: #3d3d3d;
  padding: 10px 24px;
  height: 47px;
  border-radius: 10px;
  font-family: $font-stack;
  width: 90%;
}

.progress-label {
  text-align: center;
  font-family: $font-stack;
  color: #3d3d3d;
  width: 90%;
}

.settings-field-radio {
  padding: 0px 59px 10px 24px;
}

.settings-field-header-radio {
  margin-top: 10px !important;
  font-family: $font-stack;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.settings-field-link {
  text-decoration: none;
  display: inline;
  color: #2155a3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.notranslate {
  visibility: hidden !important;
}

.select-sample .notranslate {
  display: none;
}

.settings-field-box {
  text-align: right;
}

.search-field {
  padding-top: 12px;
}

.slider-number {
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 400px;
  color: #3d3d3d;
}

.slider {
  padding-left: 20px;
  margin-bottom: 50px !important;
  background: none;
}

.files-and-reports .css-17p3wh3-MuiGrid-root {
  margin-bottom: 0px;
}

.css-17p3wh3-MuiGrid-root {
  margin-bottom: 65px !important;
}

.bigSpacer {
  margin-top: 68px;
}

.pipeline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inline-image {
  vertical-align: middle;
}

.loop {
  transform: rotate(90deg) scaleX(-1);
}

.advanced {
  padding-top: 10px;
  margin-right: 24px;
  width: 98%;
  text-align: right;
}

.advanced-link {
  color: #2155a3;
  font-family: $font-stack;
  font-size: 14px;
  text-decoration: none;
}

#advanced-row {
  border-top: 2px solid #919191;
}

.sample-kit-header {
  font-family: $font-stack;
  font-size: 18px;
  font-weight: 500;
  color: $br-green;
  display: inline-block;
}

.sample-kit-image {
  font-family: $font-stack;
  text-align: center;
  margin-top: 10px;
}

.sample-kit-text-box {
  font-family: $font-stack;
  text-align: center;
  margin-top: 24px;
  padding-bottom: 60px;
}

.sample-kit-text {
  display: inline-block;
  width: 700px;
  text-align: left;
}

.footer {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 60px;
  padding: 8px 40px 9px 43px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  z-index: 100;
}

.footer-content {
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
}

.dragndrop-box {
  flex: 1;
  height: 75%;
  width: 97%;
  margin: 4px 0 0;
  padding: 0px 16px 16px 16px;
  border-radius: 4px;
  border: dashed 2px #9aa3b4;
  background-color: #fff;
  cursor: pointer;
}

.dragndrop-box-hover {
  flex: 1;
  height: 75%;
  width: 97%;
  margin: 4px 0 0;
  padding: 0px 16px 16px 16px;
  border-radius: 4px;
  border: dashed 2px #9aa3b4;
  background-color: #eceef0;
  cursor: pointer;
}

.dragndrop-box-small {
  flex: 1;
  height: 35%;
  width: 95%;
  margin: 4px 0px 20px 0px;
  padding: 16px 16px 16px 16px;
  border-radius: 4px;
  border: dashed 2px #9aa3b4;
  background-color: #fff;
}

.dragndrop-box-large {
  height: 80%;
  width: 99%;
  padding: 0px 16px 16px 16px;
  border-radius: 4px;
  background-color: #fff;
}

.dragndrop-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dragndrop-footer {
  color: $br-active-blue;
  text-align: center;
  margin: 20px, 0px;
}

.draggable-row {
  display: flex;
}

.droppable-row {
  padding: 16px;
  border-radius: 6px;
  height: 2px;
  opacity: 0.5;
}

.file-download>div {}

.file-download>p {
  display: inline;
  margin-right: 45px;
}

.text {
  font-family: $font-stack;
  font-size: 14px;
  color: #3d3d3d;
}

.large-text {
  font-family: $font-stack;
  font-size: 14px;
  color: #3d3d3d;
}

.inline-text {
  display: inline;
  font-family: $font-stack;
  font-size: 14px;
}

.light-text {
  font-family: $font-stack;
  font-size: 12px;
  color: #bcbcbc;
}

.upload-tabs {
  width: 301px;
  border-radius: 0;
  margin-bottom: 2px;
}

.file-list-title {
  margin: 5px;
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.file-list-item {
  font-family: $font-stack;
  border-radius: 6px;
  background-color: #f6f6f6;
}

.file-list-item::selection {
  background-color: $br-green;
}

.file-list-item>* {
  border-bottom: none;
}

.table-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.table-footer>* {
  margin-left: 10px;
}

.edit-notes {
  border: 1px solid #acacac;
  padding: 24px;
  margin-top: 100px;
  margin-left: 40%;
  width: 430px;
  height: 780px;
}

.cancel-link {
  text-decoration: none;
  display: inline;
  color: #767676;
  margin-left: 68%;
  margin-right: 44px;
}

#file-extension {
  align-items: center;
  justify-content: center;
  height: 300px;
}

.login-header {
  font-family: $font-stack;
  margin-top: 50px;
  font-weight: 400;
}

.login-field {
  font-family: $font-stack;
  width: 300px;
  height: 40px;
}

.login-backdrop {
  height: 100vh;
  background-color: #edf0f2;
  background-image: url("images/B-Rshapes.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 880px 780px;
}

.login-link {
  font-family: $font-stack;
  margin-bottom: 18px;
}

.login-button {
  background-color: $br-green;
  color: #fff;
  font-family: $font-stack;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 20px 8px 20px;
  margin: 24px 0px 0px 120px;
}

.login-button:hover {
  background-color: #008741;
}

.login-button.Mui-disabled {
  background-color: #d3d3d3;
}

.eula {
  border: 1px solid #acacac;
  padding: 24px;
  margin-top: 100px;
  margin-left: 32%;
  width: 580px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.deleteexperimentmodal {
  width: 400px;
  height: 200px;
}

.eula-header {
  font-family: $font-stack;
  font-weight: 500;
  display: inline-block;
  margin-top: 0px;
}

.eula-text {
  font-family: $font-stack;
  font-size: 14px;
  color: #3d3d3d;
}

.eula-footer {
  width: 100%;
  margin-left: 27%;
}

#welcome {
  width: 94.5%;
  height: 420px;
  padding: 4px 4px 4px 20px;
  border-radius: 3px;
  box-shadow: 0 0 14px 2px #c8cdd1;
  background-color: #fff;
  margin: 124px 0px 0px 32px;
}

#welcome-wrapper {
  vertical-align: top;
  display: inline-block;
  background-position: right top;
  background-repeat: repeat-y;
  height: 100%;
  width: 100%;
}

#welcome-panel {
  vertical-align: top;
  background-repeat: no-repeat;
  height: 100%;
  width: 315px;
  float: right;
  color: #fff;
  background-color: #3b445c;
}

#welcome-header {
  margin: 3px 201px 29px 13px;
  display: inline-block;
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 700;
  color: #3d3d3d;
}

#welcome-text>p:first-child {
  margin-top: 2rem;
}

#welcome-text {
  font-family: $font-stack;
  font-size: 14px;
  color: #3d3d3d;
  border-top: solid 1px #dcdfe0;
}

#welcome-header-box {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

#addtag-modal {
  width: 440px;
  height: 240px;
  margin-top: 218px;
  margin-left: 37%;
  padding: 19px 24px 0px 24px;
  border-radius: 4px;
  background-color: #fff;
}

.addtag-field {
  font-family: $font-stack;
}

#addtag-footer {
  font-family: $font-stack;
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 15px;
}

#newuser-header {
  font-family: $font-stack;
  font-size: 25px;
  font-weight: 400;
  margin-top: 40px;
  color: #3d3d3d;
}

#newuser-modal-header {
  font-family: $font-stack;
  font-size: 25px;
  font-weight: 400;
  margin-top: 0px;
  color: #3d3d3d;
}

#newuser-text {
  font-family: $font-stack;
  font-size: 16px;
  line-height: 1.38;
  color: #3d3d3d;
}

#newuser-footer {
  font-family: $font-stack;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
}

.newuser-field {
  margin: 56px 60px 0px 0px;
}

.newuser-last-field {
  margin: 30px 60px 0px 0px;
}

#newuser-button {
  background-color: $br-green;
  font-family: $font-stack;
  font-weight: 500;
  padding: 8px 20px 8px 20px;
  margin: 15px 0px 0px 0px;
  position: absolute;
  right: 0;
}

#newuser-button:hover {
  background-color: #008741;
}

#newuser-button.Mui-disabled {
  background-color: #d3d3d3;
}

div[class^="MUIDataTable-responsiveStacked"] {
  overflow-x: hidden !important;
}

.newuser-modal {
  width: 450px;
  height: 250px;
  padding: 24px 23px 11px 24px;
  border-radius: 4px !important;
  background-color: #fff;
  margin-left: 35%;
  margin-top: 200px;
}

.tag-chip {
  text-transform: uppercase;
  margin-right: 12px;
  margin-bottom: 4px;
}

.tag-cell {
  max-width: 200px;
  min-width: 150px;
}

.add-tag {
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.delete-icon {
  padding: 0px;
}

.delete-header {
  color: #ff0000;
  font-size: 25px;
  font-family: $font-stack;
  display: inline;
  margin: 0px 5px;
}

#delete-experiment-modal {
  width: 467px;
  height: 167px;
  padding: 1px 24px 16px 16px;
  border-radius: 4px;
  background-color: #fff;
  margin-left: 35%;
  margin-top: 200px;

  a:visited {
    color: $br-active-blue;
  }
}

#delete-header-box {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

#experiment-notes {
  max-height: 400px;
  font-family: $font-stack;
  height: unset !important;
  box-shadow: none;
  background: white;
  padding: 10px;
}

#experiment-error-modal {
  width: 467px;
  height: 230px;
  padding: 0px 24px 16px 16px;
  border-radius: 4px;
  background-color: #fff;
  margin-left: 35%;
  margin-top: 200px;
}

#html-report {
  flex: 1;
  width: 50%;
  margin: 8px 16px;
}

.report-group {
  margin: 1px 0 10px;
}

.report {
  background-color: #f1f1f1;
  padding: 10px 24px;
  margin-bottom: 1px;
}

#samples-header {
  font-family: $font-stack;
  font-size: 16px;
  font-weight: 400;
}

.all-tags-modal {
  width: 440px;
  height: 250px;
  margin-top: 218px;
  margin-left: 37%;
  padding: 19px 24px 0px 24px;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: none;
}

.tags-box {
  overflow: none;
  overflow-y: auto;
  max-height: 120px;
  min-height: 120px;
}

//General
.right {
  float: right;
}

.green-background {
  background-color: $br-green !important;
}

.green-text {
  color: $br-green;
}

.link2 {
  margin-right: 16px;
}

.top-border {
  border-top: solid 1px #dcdfe0;
}

.small-padding-top {
  padding-top: 14px;
}

.mini-margin-top {
  margin-top: 5px;
}

.rotate-90deg {
  transform: rotate(90deg);
}

.bold-text {
  font-family: $font-stack;
  font-weight: 500;
}

p {
  font-family: $font-stack;
}

.MuiTableCell-root.MuiTableCell-head {
  padding-top: 5px;
  padding-bottom: 7px;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: $br-active-blue;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $br-green !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: $br-green;
}

.MuiLinearProgress-colorPrimary {
  background-color: #ededed;
}

.MuiAppBar-colorDefault {
  background-color: #fff;
}

.MuiTooltip-tooltip {
  margin-top: 5px;
  font-size: 11px;
  font-family: $font-stack;
}

.MuiFormLabel-root.Mui-focused {
  color: #872b00;
  font-family: $font-stack;
}

.MuiFormLabel-root.Mui-focused.Mui-error {
  color: #ff0000;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff0000;
  color: #ff0000;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff945f;
}

.MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ff0000;
}

.MuiOutlinedInput-root {
  background-color: #fff;
}

.MuiButton-root.Mui-disabled {
  background-color: #fff;
  border-color: #e0e0e0;
  color: #8c8c8c;
}

.MuiInputBase-root.MuiInput-root.Mui-disabled.Mui-disabled {
  color: black;
}

.MuiTab-wrapper {
  flex-direction: row-reverse;
}

.existingTabPagination .MuiTablePagination-selectLabel,
.existingTabPagination .MuiTablePagination-displayedRows {
  margin: 0 !important;
}


.no-reports {
  padding-left: 10px;
  padding-top: 10px;
  background-color: #fafafa;
}

.error-file {
  color: red;
}

/* Helvetica Neue LT Std/37 Thin Condensed */
@font-face {
  font-family: 'Helvetica Neue LT Std 37 Thin Condensed';
  src: url('./fonts/Helvetica Neue LT Std 37 Thin Condensed.otf') format('opentype');
}

/* Helvetica Neue LT Std/67 Medium Condensed */
@font-face {
  font-family: 'Helvetica Neue LT Std 67 Medium Condensed';
  src: url('./fonts/HelveticaNeueLTStd-67-MdCn.otf') format('opentype');
}

/* Helvetica Neue LT Std/57 Condensed */
@font-face {
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  src: url('./fonts/HelveticaNeueLTStd-57-Cn.otf') format('opentype');
}

.headline {
  line-height: 1;
  font-family: 'Helvetica Neue LT Std 37 Thin Condensed';
}

.subhead {
  font-family: 'Helvetica Neue LT Std 67 Medium Condensed';
}

.subtext {
  font-family: 'Helvetica Neue LT Std 57 Condensed';
}

/* login screen css */
.login-container {
  margin: 0 auto;
  max-width: 90%;
  display: flex;
  flex-direction: column;
}

.login-svg-container {
  position: relative;
  min-height: calc(70% - 80px);
}

.login-svg-image {
  width: 100%;
  height: 100%;
}

.login-text-container {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  //color: black;
  text-align: left;
  max-width: 30%;
}

.login-bg-gray-text-container {
  margin-left: 130px;
  text-align: left;
  max-width: 40%;
}

.login-heading-large {
  font-size: 78px;
  line-height: 75px;
  color: #5699ff;
  margin-bottom: 0px;
  font-family: 'Helvetica Neue LT Std 37 Thin Condensed';
}

.login-text-bold-large {
  font-size: 30px;
  font-weight: 400px;
  margin-bottom: 0px;
  font-family: 'Helvetica Neue LT Std 67 Medium Condensed';
  color: #58595b;
}

.login-text-bold-medium {
  font-size: 22px;
  font-weight: 400px;
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  color: #58595b;
}

.login-bg-gray {
  background-color: rgb(241, 241, 241);
  padding: 16px;
}

.login-heading-small {
  font-size: 24px;
  font-family: 'Helvetica Neue LT Std 67 Medium Condensed';
  color: #5699ff;
  margin: 0 0 12px 0;
}

.login-text-bold-small {
  font-size: 19px;
  font-weight: 300px;
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  color: #58595b;
  margin: 0 0 16px 0;
}

.login-list {
  font-size: 19px;
  font-weight: 200px;
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  list-style-type: square;
  margin: 0 0 16px 10px;
  color: #58595b;
}

/* Media query for small devices (e.g., mobile) */
@media (max-width: 480px) {
  .login-heading-large {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .login-heading-large {
    font-size: 44px;
  }

  .login-text-bold-large {
    font-size: 24px;
  }

  .login-text-bold-medium {
    font-size: 19px;
  }

  .login-heading-small {
    font-size: 19px;
  }

  .login-text-bold-small {
    font-size: 16px;
  }
}

/* end login screen css */

/* login screen css */
// .container {
//   margin: 0 auto;
//   max-width: 90%;
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
// }

// .svg-container {
//   position: relative;
//   flex: 1;
// }

// .svg-image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

// .text-container {
//   position: absolute;
//   top: 50%;
//   /* Adjust this value to move the text up */
//   left: 10%;
//   transform: translateY(-50%);
//   color: black;
//   text-align: left;
//   max-width: 40%;
// }

// .content-container {
//   background-color: rgb(241, 241, 241);
//   padding: 16px;
//   flex: 1;
// }

// /* Media query for smaller screens */
// @media (max-width: 768px) {
//   .text-container h1 {
//     font-size: 3rem;
//   }

//   .text-container p {
//     font-size: 1.2rem;
//   }

//   .content-container h1 {
//     font-size: 1.2rem;
//   }

//   .content-container p {
//     font-size: 1rem;
//   }

//   /* end login screen css */
// }