$popover-color: #f60;
$link-color: #f60;

@media (min-width: 1200px) {
  .Zcontainer {
    max-width: 1320px;
  }
}

main {
  min-height: 100vh;
}

.logo {
  margin: 0 20px;
  max-width: 90px;
}

.camel-case {
  text-transform: lowercase;
}

.no-wrap {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: no-wrap;
}

.sm-progress {
  width: 50px;
}

.contain-overflow {
  overflow: hidden;
}

[type="color"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="datetime"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
[type="color"],
[type="date"],
[type="datetime-local"],
[type="datetime"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
textarea {
  box-shadow: unset;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #c2c2c2;
  background-color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0px 9999px rgba(250, 250, 250, 1);
}

.fullWidth {
  width: 100%;
}

input[type="range"].fullWidth {
  margin-top: 15px;
}

.errorText {
  color: #ff0000;
  margin-bottom: 1rem;
}

.mb0 {
  margin-bottom: 0;
}

#app-bar {
  border-bottom: 4px solid #00a650;
  -webkit-box-shadow: unset;
  shadow: unset;
}

#nav-bar {
  border-right: 1px solid #a2aabb;
}

#nav-bar .MuiListItem-root {
  color: #a9a9a9;
}

#nav-bar .MuiListItem-root.Mui-selected,
#nav-bar .MuiListItem-root.Mui-selected:hover {
  color: #00a650;
  background-color: #fff;
  border-bottom: 1px solid #00a650;
}

.pale-grey {
  color: #f6f7f9;
}
.bg-pale-grey {
  background: #f6f7f9;
}

.blue-grey {
  color: #969eae;
}
.bg-blue-grey {
  background: #969eae;
}

.light-blue-grey {
  color: #a2aabb;
}
.bg-light-blue-grey {
  background: #a2aabb;
}

a,
a:active,
a:focus,
a:visited,
.br-green {
  color: #00a650;
}
.bg-br-green {
  background: #00a650;
}

.MuiButton-root.default {
  height: 48px;
  padding: 0 15px;
  background: #f6f7f9;
  border: solid 2px #dadde4;
}

.MuiDataGrid-toolbarContainer {
  height: 80px;
  justify-content: flex-end;
}

input[aria-label=Search] {
  box-shadow: none;
}

input[aria-label=Search]:focus {
  box-shadow: none;
  border: none;
}

